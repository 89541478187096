<template>
  <div id="footer">
    <div v-if="isMobile()">
      <b-modal
        id="modal-1"
        ref="myModal"
        hide-footer
        centered
        v-show="show"
        @show-me="show = true"
        @hide-me="show = false"
      >
        <template #modal-header="{ close }">
          <h3 class="purpleText">
            Keep up to date with our latest news
          </h3>
          <b-button
            @click="
              close();
              closeModal();
            "
          >
            X
          </b-button>
        </template>
        <section class="newsLetterModal">
          <b-container>
            <b-row>
              <b-col lg="1" md="0" cols="0"></b-col>
              <b-col>
                <span v-html="wpInfo" class="signUp"></span>
                <span
                  >Click the X in the right hand corner to access our
                  site.</span
                >
              </b-col>
              <b-col lg="1" md="0" cols="0"></b-col>
            </b-row>
            <b-row>
              <b-col lg="1" md="0" cols="0"></b-col>
              <b-col
                lg="10"
                md="12"
                style="padding-left:0; padding-right:0;"
                class="chimp"
              >
                <b-embed
                  type="embed"
                  aspect="1by1"
                  src="https://live-scottishswim.ocs-software.com/wp-content/themes/twentytwentyone/mail_sign_up/mailChimp.html"
                ></b-embed>
              </b-col>
              <b-col lg="1" md="0" cols="0"></b-col>
            </b-row>
          </b-container>
        </section>
      </b-modal>
    </div>
    <section>
      <b-container>
        <b-row>
          <b-col>
            <p class="visit">
              For the latest news stories on our Scottish athletes at the
              Olympic and Paralympic Games visit
              <a
                class="ssLink"
                href="https://scottishswimming.com"
                target="_blank"
                >www.scottishswimming.com</a
              >
            </p>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <section>
      <b-container>
        <b-row>
          <b-col lg="1" md="0" cols="0"></b-col>
          <b-col
            v-for="(spon, index) in sponsors.slice().reverse()"
            :key="index"
            ><a :href="spon.acf.url">
              <b-img class="spon" :src="spon.acf.image"></b-img>
            </a>
          </b-col>
          <b-col lg="1" md="0" cols="0"></b-col>
        </b-row>
      </b-container>
    </section>
    <section class="newsLetter">
      <b-container>
        <b-row>
          <b-col lg="1" md="0" cols="0"></b-col>
          <b-col>
            <span v-html="wpInfo" class="signUp"> </span>
          </b-col>
          <b-col lg="1" md="0" cols="0"></b-col>
        </b-row>
        <b-row>
          <b-col lg="1" md="0" cols="0"></b-col>
          <b-col
            lg="10"
            md="12"
            style="padding-left:0; padding-right:0;"
            class="chimp"
          >
            <b-embed
              type="embed"
              aspect="1by1"
              src="https://live-scottishswim.ocs-software.com/wp-content/themes/twentytwentyone/mail_sign_up/mailChimp.html"
            ></b-embed>

            <!--         <iframe
            style="height: 335px; width: 100%; border: none!important;"
            src="https://live-scottishswim.ocs-software.com/wp-content/themes/twentytwentyone/mail_sign_up/mailChimp.html"
          >
          </iframe> -->
          </b-col>
          <b-col lg="1" md="0" cols="0"></b-col>
        </b-row>
      </b-container>
    </section>
    <section class="purple">
      <b-container>
        <b-row class="miniMenu">
          <b-col lg="1" md="0" cols="0"></b-col>
          <b-col lg="2" md="12" cols="12">
            <a href="https://www.scottishswimming.com/">
              <b-img
                class="sLogo"
                src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scottish-swim/2021/06/scottish-swimming-2.png"
              ></b-img>
            </a>
          </b-col>
          <b-col lg="2" md="3" cols="6">
            <b-nav-item
              target="_blank"
              class="miniLink"
              href="https://www.scottishswimming.com/about-us.aspx"
              >About Us</b-nav-item
            >
            <b-nav-item
              target="_blank"
              class="miniLink"
              href="https://www.scottishswimming.com/contact-us.aspx"
              >Contact Us</b-nav-item
            >
            <b-nav-item
              target="_blank"
              class="miniLink"
              href="https://www.scottishswimming.com/about-us/job-opportunities.aspx"
              >Jobs</b-nav-item
            >
            <b-nav-item
              class="miniLink"
              target="_blank"
              href="https://www.scottishswimming.com/clubs/volunteers.aspx"
              >Volunteer</b-nav-item
            >
          </b-col>
          <b-col class="sideBlue" lg="2" md="3" cols="6">
            <b-nav-item
              target="_blank"
              class="miniLink"
              href="https://www.scottishswimming.com/media.aspx"
              >Media Centre</b-nav-item
            >
            <b-nav-item
              target="_blank"
              class="miniLink"
              href="https://www.scottishswimming.com/about-us/sponsorship-opportunities.aspx"
              >Sponsors</b-nav-item
            >
            <b-nav-item
              target="_blank"
              class="miniLink"
              href="https://scottishswimming.azolve.com/Account.mvc/Login?ReturnUrl=%2f"
              >Online Membership</b-nav-item
            >
          </b-col>
          <b-col class="sideBlue noBlueMob" lg="2" md="3" cols="6">
            <b-nav-item
              class="miniLink"
              target="_blank"
              href="https://www.swimwales.org/"
              >Swim Wales</b-nav-item
            >
            <b-nav-item
              target="_blank"
              class="miniLink"
              href="https://www.swimming.org/swimengland/"
              >The ASA</b-nav-item
            >
            <b-nav-item
              class="miniLink"
              href="https://www.britishswimming.org/"
              target="_blank"
              >British Swimming</b-nav-item
            >
          </b-col>
          <b-col class="sideBlue" lg="2" md="3" cols="6">
            <b-nav-item
              class="miniLink"
              target="_blank"
              href="https://www.scottishswimming.com/privacy-policy.aspx"
              >Privacy Policy</b-nav-item
            >
            <b-nav-item
              class="miniLink"
              target="_blank"
              href="https://www.scottishswimming.com/sitemap.aspx"
              >Sitemap</b-nav-item
            >
            <b-nav-item
              class="miniLink"
              target="_blank"
              href="https://www.scottishswimming.com/terms-and-conditions.aspx"
              >Terms & Conditions</b-nav-item
            >
          </b-col>
          <b-col lg="1" md="0" cols="0"></b-col>
        </b-row>
        <b-row>
          <b-col lg="1" md="0" cols="0"></b-col>
          <b-col lg="2" md="3" cols="0"></b-col>
          <b-col lg="2" md="3" cols="0"></b-col>
          <b-col lg="2" md="3" cols="0"></b-col>
          <b-col lg="2" md="3" cols="0"></b-col>
          <b-col lg="3" md="12" cols="12" class="mobPad">
            <b-nav>
              <b-nav-item
                class="socailIcon"
                href="https://www.facebook.com/ScottishSwimming"
              >
                <b-img
                  class="social"
                  src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scottish-swim/2021/06/Facebook-2.png"
                ></b-img>
              </b-nav-item>
              <b-nav-item
                class="socailIcon"
                href="https://twitter.com/ScottishSwim"
              >
                <b-img
                  class="social"
                  src="http://dev-scottishswim.ocs-software.com/wp-content/uploads/2021/06/Twitter.png"
                ></b-img>
              </b-nav-item>
              <b-nav-item
                class="socailIcon"
                href="https://www.youtube.com/user/ScottishSwimming"
              >
                <b-img
                  class="social"
                  src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scottish-swim/2021/06/Youtube-2.png"
                ></b-img>
              </b-nav-item>
              <b-nav-item
                class="socailIcon"
                href="https://www.instagram.com/scottishswimming/"
              >
                <b-img
                  class="social"
                  src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scottish-swim/2021/06/Instagram-2.png"
                ></b-img>
              </b-nav-item>
              <b-nav-item
                class="socailIcon"
                href="https://www.flickr.com/people/scottishswimming/"
              >
                <b-img
                  class="social"
                  src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scottish-swim/2021/06/Group-26-1.png"
                ></b-img>
              </b-nav-item>
            </b-nav>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <section class="deepPurple">
      <b-container>
        <b-row>
          <b-col lg="1" md="0" cols="0"></b-col>
          <b-col>
            <p class="copy">
              © Scottish Swimming 2021
            </p>
          </b-col>
          <b-col>
            <p class="copy right">
              <a href="https://ocs-sport.com/">
                Site by OCS Software
              </a>
            </p>
          </b-col>
          <b-col lg="1" md="0" cols="0"></b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "foot",
  data() {
    return {
      sponsors: [],
      email: "",
      wpInfo: "",
      showModal: false
    };
  },
  methods: {
    show() {
      this.showModal = false;
      this.$emit("show-me");
    },
    closeModal() {
      console.log("test");
      localStorage.setItem("showModal", "no");
    },
    isMobile() {
      this.showModal = false;
      return false;
    }
  },
  mounted() {
    const showModal = localStorage.getItem("showModal");
    console.log(showModal);
    if (showModal === null) setTimeout(() => this.$refs.myModal.show(), 3500);
    axios
      .get(process.env.VUE_APP_API_URL + "/?rest_route=/wp/v2/sponsors")
      .then(response => {
        this.sponsors = response.data;
        return axios.get(
          process.env.VUE_APP_API_URL + "/?rest_route=/wp/v2/pages/778"
        );
      })
      .then(response => {
        this.wpInfo = response.data.acf.newsletter_text;
      });
  }
};
</script>

<style scoped>
#footer {
  padding-top: 5%;
}
.visit {
  text-align: center;
  padding-bottom: 20px;
  font-size: 16px;
}
.ssLink {
  color: #0491cf;
}
.purple {
  background: var(--unnamed-color-201546) 0% 0% no-repeat padding-box;
  background: #201546 0% 0% no-repeat padding-box;
}
.purpleText {
  color: #fff;
}
.miniMenu {
  padding-top: 36px;
}
li {
  list-style: none;
}
.miniLink > a {
  color: #fff !important;
  font-size: 14px;
}
.spon {
  padding-bottom: 50px;
  width: 90%;
}
.sideBlue {
  border-left: 1px solid #0491cf;
}
.social {
  width: 38px;
}
.socialRow {
  float: right;
}
.deepPurple {
  padding-top: 24px;
  background-color: #1b113e;
  margin-top: -1px;
}
.copy {
  font-size: 14px;
  color: #fff;
}
.copy > a {
  color: #fff !important;
}
.right {
  text-align: right;
  margin-right: 54px;
}
.socailIcon > a {
  padding: 0;
}
.nav {
  padding-left: 11px;
}
.newsLetter {
  background-color: #f1f0f1;
  padding: 50px;
}
.newsLetterModal {
  background-color: #f1f0f1;
  padding: 25px;
}
.update {
  font-size: 18px;
  padding-right: 50px;
  padding-top: 12px;
}
.submit {
  background-color: #0491cf;
  border-radius: 0px 33px 33px 0px;
  width: 150px;
  padding: 5px 0;
  margin-left: 10px;
  border-color: #0491cf;
}
.form-control {
  width: 400px;
  padding-right: 20px;
}
.signUp {
  padding: 20px 0;
}
.chimp > .embed-responsive::before {
  padding-top: 35%;
}
::v-deep .modal-header {
  background: var(--unnamed-color-201546) 0% 0% no-repeat padding-box;
  background: #201546 0% 0% no-repeat padding-box;
}
@media only screen and (max-width: 1024px) {
  .chimp > .embed-responsive::before {
    padding-top: 60%;
  }
}
@media only screen and (max-width: 768px) {
  .sLogo {
    padding-bottom: 20px;
    width: 35%;
  }
  .chimp > .embed-responsive::before {
    padding-top: 46%;
  }
}
@media only screen and (max-width: 425px) {
  .noBlueMob {
    border-left: 0;
  }
  .mobPad {
    padding: 30px 15px;
  }
  .chimp > .embed-responsive::before {
    padding-top: 88%;
  }
}
@media (min-width: 576px) {
  ::v-deep .modal-dialog {
    max-width: 1200px;
  }
}
</style>
