import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    props: true
  },
  {
    path: "/disciplines",
    name: "home",
    component: Home,
    props: true
  },
  {
    path: "/volunteer",
    name: "home",
    component: Home,
    props: true
  },
  {
    path: "/swimming",
    name: "swimming",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue"),
    props: true
  },
  {
    path: "/water-polo",
    name: "water-polo",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue"),
    props: true
  },
  {
    path: "/artistic-swimming",
    name: "artistic-swimming",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue"),
    props: true
  },
  {
    path: "/diving",
    name: "diving",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue"),
    props: true
  },
  {
    path: "/open-water",
    name: "open-water",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue"),
    props: true
  },
  {
    path: "/masters",
    name: "masters",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue"),
    props: true
  },
  {
    path: "/para-swimming",
    name: "para-swimming",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue"),
    props: true
  },
];

const router = new VueRouter({
  routes
});

export default router;
