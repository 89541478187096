<template>
  <div class="dis">
    <b-row>
      <b-col lg="3" cols='12'>
        <h2 class="find">
          Volunteer
        </h2>
      </b-col>
      <b-col lg="9">
        <b-container>
          <b-row
            v-for="(group, index) in groups.slice().reverse()"
            :key="index"
          >
            <b-col class="disCol" lg="5">
                <div>
                  <b-img :src="group.acf.image"></b-img>
                  <br />
                  <span class="title db">{{ group.acf.title }}</span>
                </div>
            </b-col>
            <b-col lg="7">
              <span class="blurb" v-html="group.acf.blurb"></span>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "groups",
  data() {
    return {
      groups: [],
    };
  },
  mounted() {
    axios
      .get(
        process.env.VUE_APP_API_URL + "/?rest_route=/wp/v2/groups"
      )
      .then((response) => {
        this.groups = response.data;
      });
  },
};
</script>

<style scoped>
.dis{
    padding: 5% 10%;
}
li {
  list-style: none;
}
.outer {
  width: 395px;
  height: 361px;
  overflow: hidden;
  margin: auto;
}
.title {
  height: 66px;
  width: 240px;
  border-radius: 0px 33px 33px 0px;
  font-size: 18px;
  color: #fff;
  margin-top: -33px;
  position: absolute;
  padding: 20px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #201546;
}

.find {
  font-weight: bold;
  color: #2a5c7e;
  font: normal normal bold 40px/23px Arial;
  position: absolute;
  letter-spacing: 0px;
  line-height: normal;
}
::v-deep .blurb > p {
  font-size: 16px;
  color: #707070;
  padding: 5px;
}
.disCol {
  padding: 0 5px 50px 5px;
}
@media only screen and (max-width: 1024px) {
  ::v-deep .blurb > p {
    font-size: 14px;
    color: #707070;
    padding: 5px;
  }
  .find {
    font-size: 34px;
  }
}
@media only screen and (max-width: 768px) {
  .find{
    position: relative;
    padding-bottom: 20px;
  }
}
</style>
