
<template>
  <div id="app">
    <router-view :key="$route.fullPath"/>
    <Footer/>
        <div class="cookie" v-if="show === true">
      <b-container>
      <p class='mobPad'>
        Click <a href="#" @click.prevent="disableTracking">here</a>, to disable
        the tracking through Google Analytics.
      </p>
      <b-button class='closeCookie' @click="toggleShow">
        x
      </b-button>
      </b-container>
    </div>
  </div>
</template>

<script>
import Footer from '@/components/footer.vue'
export default {
  components:{Footer},
  data(){
    return{
      show: true,
    }
  },
    methods: {
    disableTracking: function() {
      this.toggleShow();
      this.$ga.disable();
    },
    toggleShow() {
      this.show = !this.show,
      localStorage.setItem("show", "no");
    },
  },
    mounted() {
    const show = localStorage.getItem("show");
    if (show === 'no') {
      this.show = false;
    }
    else this.show = true
    }
}
</script>

<style>
#app {
  font-family: Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

img{
  width: 100%;
}
.cookie {
  position: fixed;
  bottom: 0px;
  z-index: 10000;
  min-height: 80px;
  width: 100%;
  padding: 15px;
  background-color: #201546;
  text-align: center;
  color: #fff;
}
.closeCookie{
  position: absolute;
    top: 0;
    right: 10px;
    background-color: transparent!important;
    border: 0!important;
    font-size: 25px!important;
}
@media only screen and (max-width: 425px) {
  .mobPad{
    padding: 0 20px;
  }
}

</style>
