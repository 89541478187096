<template>
  <div class="home">
    <b-carousel
      id="carousel-1"
      :interval="4000"
      background="#ababab"
      img-width="1024"
      img-height="480"
    ><template v-if="tabIndex === 2">
      <b-carousel-slide :img-src="wpFields.volunteer"></b-carousel-slide>
      </template>
      <template v-else>
      <b-carousel-slide :img-src="wpFields.banner_1"></b-carousel-slide>
      <b-carousel-slide :img-src="wpFields.banner_2"></b-carousel-slide>
      <b-carousel-slide :img-src="wpFields.banner_3"></b-carousel-slide>
      <b-carousel-slide :img-src="wpFields.banner_4"></b-carousel-slide>
      <b-carousel-slide :img-src="wpFields.banner_5"></b-carousel-slide>
      <b-carousel-slide :img-src="wpFields.banner_6"></b-carousel-slide>
      <b-carousel-slide :img-src="wpFields.banner_7"></b-carousel-slide>
      <b-carousel-slide :img-src="wpFields.banner_8"></b-carousel-slide>
      <b-carousel-slide :img-src="wpFields.banner_9"></b-carousel-slide>
      <b-carousel-slide :img-src="wpFields.banner_10"></b-carousel-slide>
      <b-carousel-slide :img-src="wpFields.banner_11"></b-carousel-slide>
      <b-carousel-slide :img-src="wpFields.banner_12"></b-carousel-slide>
      <b-carousel-slide :img-src="wpFields.banner_13"></b-carousel-slide>
      <b-carousel-slide :img-src="wpFields.banner_14"></b-carousel-slide>
      </template>
    </b-carousel>
    <b-tabs
      v-model="tabIndex"
      class="custom"
      content-class="mt-3"
      align="center"
    >
      <b-tab @click="addHashToLocation('')">
        <template #title>
          <span class="navTitle">FIND YOUR NEAREST CLUB</span>
          <br />
          <span class="downTri hide"></span>
        </template>
      </b-tab>
      <b-tab @click="addHashToLocation('disciplines')">
        <template #title>
          <span class="navTitle">DISCIPLINES</span>
          <br />
          <span class="downTri hide"></span>
        </template>
        <Dis />
      </b-tab>
           <b-tab @click="addHashToLocation('vounteer')">
        <template #title>
          <span class="navTitle">VOLUNTEER</span>
          <br />
          <span class="downTri hide"></span>
        </template>
        <Groups
      /></b-tab>
  <!--     <b-tab>
        <template #title>
          <span class="navTitle">DOWNLOADS</span>
          <br />
          <span class="downTri hide"></span>
        </template>
        <Downloads
      />
        </b-tab> -->
    </b-tabs>
    <div class="dis">
      <b-row>
        <b-col lg="3" cols="12">
          <h2 class="find">
            Find your nearest club
          </h2>
        </b-col>
        <b-col lg="9" cols="12" class="disCol">
          <b-embed type='embed' aspect='1by1' src="https://live-scottishswim.ocs-software.com/wp-content/themes/twentytwentyone/club_finder/ClubTest.html"></b-embed>
  <!--        <iframe
            style="height: 118vh; width: 100%; border: none!important;"
            src="https://live-scottishswim.ocs-software.com/wp-content/themes/twentytwentyone/club_finder/ClubTest.html"
          >
          </iframe> -->
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import Dis from "@/components/disciplines.vue";
import Groups from "@/components/groups.vue";
//import Downloads from "@/components/downloads.vue";
import axios from "axios";
export default {
  name: "home",
  components: {
    Dis, Groups,
    //   Downloads
  },
  methods:{
    addHashToLocation(params) {
  history.pushState(
    {},
    null,
    this.$route.path + '#/' + encodeURIComponent(params)
  )
}
  },
  data() {
    return {
      wpFields: [],
      tabIndex: this.$route.query.tabIndex,
    };
  },
  mounted() {
    axios
      .get(process.env.VUE_APP_API_URL + "/?rest_route=/wp/v2/pages/778")
      .then((response) => {
        this.wpFields = response.data.acf;
      });
  },
};
</script>

<style scoped>
.disCol {
  padding: 0 5px 50px 5px;
}
.custom {
  padding-bottom: 5px;
}
.dis {
  padding: 50px 10%;
}
::v-deep .custom > div > .nav-tabs {
  background-color: #201546;
  text-align: center;
  height: 100px;
}
::v-deep .custom > div > .nav-tabs > .nav-item > .nav-link {
  background-color: #201546;
  color: #fff;
  border: transparent;
  margin-bottom: 0 !important;
}
::v-deep .custom > div > .nav-tabs > .nav-item > .nav-link.active {
  background-color: #201546 !important;
  color: #fff !important;
  border: transparent;
  margin-bottom: 0 !important;
  font-weight: bold;
}
::v-deep .custom > div > .nav-tabs > .nav-item {
  margin-bottom: 0 !important;
  padding: 25px 0;
}
.downTri {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 12.5px 0 12.5px;
  border-color: #201546 transparent transparent transparent;
  margin-left: -14px;
  margin-top: 35px;
}
.downTri {
  display: none;
}
.active > .downTri {
  display: initial;
}
.find {
  font-weight: bold;
  color: #2a5c7e;
  font: normal normal bold 40px/23px Arial;
  position: absolute;
  letter-spacing: 0px;
  line-height: normal;
}
::v-deep .az-drawer {
  box-shadow: 0;
}

.navTitle {
  font-size: 20px;
}
.embed-responsive{
    width: 100%;
    border: none !important;
}
@media only screen and (max-width: 425px) {
  .navTitle {
    font-size: 14px;
  }
  ::v-deep .nav-tabs > .nav-item > .nav-link {
    padding: 0.5rem;
  }
  ::v-deep .nav-tabs {
    flex-wrap: nowrap;
    white-space: nowrap;
    max-width: 700px;
    overflow: auto;
    /*justify-content: left !important; */
  }
  .active > .downTri {
    display: none;
  }
  .find {
    position: relative;
  }
}
@media only screen and (max-width: 390px) {
  .navTitle {
    font-size: 12px;
  }
}
</style>
