<template>
  <div class="dis">
    <b-row>
      <b-col
        class="disCol"
        lg="3"
        md="12"
        v-for="(dis, index) in disciplines.slice().reverse()"
        :key="index"
      >
          <div>
            <b-img :src="dis.acf.image"></b-img>
            <br />
            <span
              :style="'background-color:' + dis.acf.button_colour + ';'"
              class="title db"
              >{{ dis.acf.title }}</span
            >
          </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "disciplines",
  data() {
    return {
      disciplines: [],
    };
  },
  mounted() {
    axios
      .get(
        process.env.VUE_APP_API_URL + "/?rest_route=/wp/v2/disciplines"
      )
      .then((response) => {
        this.disciplines = response.data;
      });
  },
};
</script>

<style scoped>
li {
  list-style: none;
}
.dis {
  padding: 5% 10%;
}
.disCol > .nav-item > .nav-link {
  padding: 0;
}
.disCol {
  padding: 0 5px 50px 5px;
}
.title {
  height: 66px;
  width: 240px;
  border-radius: 0px 33px 33px 0px;
  font-size: 18px;
  color: #fff;
  margin-top: -33px;
  position: absolute;
  padding: 20px;

  text-transform: uppercase;
}
/* .title:hover {
  font-weight: bold;
} */

@media only screen and (max-width: 1024px) {
  .title {
    font-size: 15px;
    width: 78%;
    padding: 20px 14px;
    margin-top: -28px;
    height: 58px;
  }
  .disCol.col-lg-3 {
    flex: 0 0 33.3%;
    max-width: 33.3%;
  }
}
@media only screen and (max-width: 768px) {
  .disCol.col-lg-3 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
</style>
